<template>
  <AuthenticatedLayout>
    <div
      class="cadyco-objects"
    >
      <iframe
        v-if="displayMapAsIndicator"
        :src="mapIndicatorUrl"
      />
      <div v-else>
        TODO: display formation map
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@layouts/AuthenticatedLayout'
import libraryService from '@/api/indicator/library.service'
import formationService from '@/api/formation/formation.service'

export default {
  name: 'CadycoObjects',
  components: { AuthenticatedLayout },
  data () {
    return {
      displayMapAsIndicator: false,
      mapIndicatorUrl: undefined,
      formationList: []
    }
  },
  created () {
    libraryService.getPublicIndicators('').then((data) => {
      if (data.success) {
        data.publicIndicators.forEach((publicIndicator) => {
          if (publicIndicator.title === 'Carte des formations') { // Todo: find a better way to identify it
            this.displayMapAsIndicator = true
            this.mapIndicatorUrl = 'shiny/' + publicIndicator.localUrl
          }
        })
        // Handle Map indicator
        if (!this.displayMapAsIndicator) {
          this.getFormations()
        }
      }
    })
  },
  methods: {
    getFormations () {
      formationService.getFormations('').then((data) => {
        if (data.success) {
          this.formationList = data.formations
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.cadyco-objects {
  height: calc(100% - #{$desktop-search-bar-height});

  iframe {
    border: none;
    height: calc(100% - 4px); // TODO to fix
    width: 100%;
  }
}
</style>
