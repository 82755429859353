import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  getFormations,
  getFormationDetails,
  deleteFormation,
  importFormation,
  addFormationToPortFolio,
  addFormationsToPortFolio
}

const FORMATION_PATH = '/formation.formation'

/**
 * Get all formations in database that name match with the filter ('' filter means all formations)
 */
function getFormations (filter) {
  return axios.get(constants.JSON_WS_URL + FORMATION_PATH + '/get-formations', {
    params: {
      p_auth: getCookie('pauth'),
      nameFilter: filter
    }
  }).then(response => response.data)
}

/**
 * Get all stored fields concerning the formation in database
 */
function getFormationDetails (formationId) {
  return axios.get(constants.JSON_WS_URL + FORMATION_PATH + '/get-formation-details', {
    params: {
      p_auth: getCookie('pauth'),
      formationId: formationId
    }
  }).then(response => response.data)
}

/**
 * Delete formations in database by id
 */
function deleteFormation (formationId) {
  return axios.get(constants.JSON_WS_URL + FORMATION_PATH + '/delete-formation', {
    params: {
      p_auth: getCookie('pauth'),
      formationId: formationId
    }
  }).then(response => response.data)
}

/**
 * Import the Formation file in formData into app Formations' database
 */
function importFormation (formData) {
  formData.append('p_auth', getCookie('pauth'))

  return axios.post(constants.JSON_WS_URL + FORMATION_PATH + '/import-formation-json', formData)
    .then(response => response.data)
}

/**
 * Add dataBase's Formation into user's PortFolio
 */
function addFormationToPortFolio (formationId) {
  return axios.get(constants.JSON_WS_URL + FORMATION_PATH + '/add-formation-to-portfolio', {
    params: {
      p_auth: getCookie('pauth'),
      formationId: formationId
    }
  }).then(response => response.data)
}

/**
 * Add dataBase's Formations into user's PortFolio
 */
function addFormationsToPortFolio () {
  return axios.get(constants.JSON_WS_URL + FORMATION_PATH + '/add-formations-to-portfolio', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}
